import {createStore} from 'vuex'
import errorModule from '@/common/store/errors';
import userModule from '@/modules/Users/store/user';
import router from '@/common/routes/router'

export default createStore({
  modules: {
    errors: errorModule,
    user: userModule,
  },

  actions: {
    error(context, errorText) {
      router.push({
        name: "ErrorPage",
        params: { text: errorText },
      });
    },

    route(context, { name, params }) {
      router.push({
        name: name,
        params: params,
      });
    },
  },

})
