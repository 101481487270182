<template>
    <div class="col-4">
        <router-link class="link" :to=route>
            <div class="surface-card p-4 shadow-2 border-round hover:bg-bluegray-50">
                <div class="text-4xl font-medium text-base">
                    <i class="text-4xl pi pi-fw pi-chart-pie"></i>
                    {{ title }}
                </div>
                <div class="surface-300 border-round my-4">
                    <div class="bg-teal-500 border-round" style="height: 4px;"></div>
                </div>
                <p class="font-medium text-lg text-500 mb-0">{{ description }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "ServiceCard",
    props: ['title', 'description', 'route'],
}
</script>

<style scoped>
.surface-card {
    color: var(--text-color);
    min-height: 250px;
}
</style>
