<template>
    <div class="layout-menu-container">
        <AppSubmenu
            :items="menuList"
            class="layout-menu"
            :root="true"
            @menuitem-click="onMenuItemClick"
        />
    </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";

export default {
    components: {
        AppSubmenu,
    },

    data() {
        return {
            companyCategories: null,
        };
    },

    props: {
        model: Array,
    },
    methods: {
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
            this.$emit("hideMenu");
        },
    },
    computed: {
        menuList() {
            return [
                {
                    label: "Меню",
                    items: [
                        {
                            label: "Главная страница",
                            icon: "pi pi-fw pi-home",
                            to: {
                                name: "ToolList",
                            },
                        },
                        {
                            label: "Monitor",
                            icon: "pi pi-fw pi-chart-pie",
                            to: {
                                name: "Monitor",
                            },
                        },
                    ],
                },
            ];
        },
    },
};
</script>

<style scoped>
.link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #000;
    transition: color 0.2s;
    border-radius: 12px;
    transition: background-color 0.15s;
    padding: 1rem 0.8rem;
}

.link:hover {
    background-color: rgb(231, 231, 231);
    border-radius: 12px;
}

.link i {
    margin-right: 10px;
}
</style>
