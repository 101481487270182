<template>
    <div class="layout-topbar">
        <router-link class="flex" to="/">
            <img src="/../images/logo.png" class="flex-container__main-logo" alt="promodo">
        </router-link>
        <button
            v-if="isAuthorized"
            class="p-link layout-menu-button layout-topbar-button"
            @click="onMenuToggle"
        >
            <i class="pi pi-bars"></i>
        </button>

        <button
            class="p-link layout-topbar-menu-button layout-topbar-button"
            v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'scalein',
                leaveToClass: 'hidden',
                leaveActiveClass: 'fadeout',
                hideOnOutsideClick: true,
            }"
        >
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <ul
            v-if="isAuthorized"
            class="layout-topbar-menu hidden lg:flex origin-top"
        >
<!--            <li v-if="isAdmin" class="mr-4" @click="toAdminpanel">-->
<!--                <i class="pi pi-id-card p-text-secondary mr-2"></i>-->
<!--                <span class="p-text-secondary">Админ. панель</span>-->
<!--            </li>-->

            <li @click="toggleMenu">
                <img
                    v-if="userInfoAvatar"
                    class="avatar"
                    :src="userInfoAvatar"
                />

                {{ userInfoName }}

                <Menu ref="menu" :model="overlayMenuItems" :popup="true" />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            overlayMenuItems: [
                {
                    label: "Выйти",
                    icon: "pi pi-sign-out",
                    command: () => {
                        this.logout();
                    },
                },
            ],
        };
    },

    methods: {
        hideNotification(id) {
            this.$store.dispatch("deleteNotification", id);
        },

        logout() {
            this.$store.dispatch("logout");
            window.location = "/logout";
        },

        toggleNotificationTable(event) {
            this.$refs.notifypanel.toggle(event);
        },

        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },

        onMenuToggle(event) {
            this.$emit("menu-toggle", event);
        },
        onTopbarMenuToggle(event) {
            this.$emit("topbar-menu-toggle", event);
        },
        //
        // toAdminpanel() {
        //     this.$store.dispatch("route", {
        //         name: "AdminPanelIndex",
        //     });
        // },
    },
    computed: {
        isAuthorized() {
            return this.$store.getters.isAuthorized;
        },

        // isAdmin() {
        //     return this.$store.getters.isAdmin;
        // },

        userInfoName() {
            return this.$store.getters.getUserName;
        },

        userInfoAvatar() {
            return this.$store.getters.getUserAvatar;
        },

        userNotificationCount() {
            return this.$store.getters.getNotificationCount;
        },

        userNotifications() {
            return this.$store.getters.getNotifications;
        },

        darkTheme() {
            return this.$appState.darkTheme;
        },
    },
};
</script>

<style scoped>
li {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 10px;
}

li i {
    font-size: 2rem;
}

li:hover {
    background-color: rgb(235, 235, 235);
}

.avatar {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.flex-container__main-logo {
    width: 150px;
    height: auto;
}
/*.logo {*/
/*    margin: auto;*/
/*    font-size: 1.5rem;*/
/*    color: #4c4c4c;*/
/*    font-weight: 600;*/
/*}*/
</style>
