export default {
    state: () => ({
        errorList: [],
        isShowDialog: false,
    }),
    mutations: {
        showErrorDialog(state, visibility) {
            state.isShowDialog = visibility;
        },

        setErrorList(state, errorList) {
            if (Array.isArray(errorList)) {
                state.errorList = errorList;
            } else
                state.errorList = [errorList];
        }
    },
    actions: {
        showErrorDialog(context, response) {
            console.log(response);
            context.commit('showErrorDialog', true);

            if (response?.data?.errors) {
                context.commit('setErrorList', response.data.errors);
                return;
            }

            if (response?.data?.message) {
                context.commit('setErrorList', response.data.message);
                return;
            }

            if (response?.data) {
                context.commit('setErrorList', response.data);
                return;
            }

            context.commit('setErrorList', 'Произошла неопознанная ошибка');
        },

        closeErrorDialog(context) {
            context.commit('showErrorDialog', false);
        }
    },

    getters: {
        isShowErrorDialog(state) {
            return state.isShowDialog;
        },

        errorList(state) {
            return state.errorList;
        }
    }
}