<template>
    <Dialog
        v-if="isShowDialog"
        :visible="true"
        :style="{ maxWidth: '80%', width: '850px' }"
        header="Список ошибок"
        :modal="true"
        class="p-fluid"
        @update:visible="close"
    >
        <div v-for="(error, index) in errorList" :key="index">
            <p v-for="oneError in errorToErrorList(error)" :key="oneError">
                {{ oneError }}
            </p>
        </div>

        <template #footer>
            <Button
                label="Закрыть окно"
                icon="pi pi-check"
                class="p-button-success"
                @click="close"
            />
        </template>
    </Dialog>
</template>

<script>
export default {
    methods: {
        errorToErrorList(error) {
            if (Array.isArray(error)) {
                return error;
            }

            return [error];
        },

        close() {
            this.$store.dispatch("closeErrorDialog");
        },
    },

    computed: {
        isShowDialog() {
            return this.$store.getters.isShowErrorDialog;
        },

        errorList() {
            return this.$store.getters.errorList;
        },
    },
};
</script>
