<template>
    <div class="p-grid mb-5">
        <div class="p-col-2">
            <div class="nameline">
                <h1><i class="text-4xl pi pi-fw pi-book"></i>Facebook monitor</h1>
            </div>
            <div class="grid">
                <service-card
                    title="Монитор рекламных аккаунтов"
                    description="Мониторит состояние рекламных аккаунтов и присылает отчёт в Telegram."
                    route="monitor"
                />
            </div>

        </div>
    </div>
</template>

<script>
import ServiceCard from "@/common/components/ServiceCard";

export default {
    name: "MainPage",
    components: {
        ServiceCard: ServiceCard,
    },
};
</script>
