import GuestPage from "@/common/pages/GuestPage";
import MainPage from "@/common/pages/MainPage";
import ErrorPage from "@/common/pages/ErrorPage";

const routes = [
    {
        path: '/guest',
        name: 'Guest',
        component: GuestPage
    },
    {
        path: '/',
        name: 'ToolList',
        component: MainPage
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'ErrorPage',
        props: true,
        component: ErrorPage
    }
];

export default routes;
