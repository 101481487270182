import {createRouter, createWebHistory} from 'vue-router';
import defaultRoutes from '@/common/routes/common';
// import projectsRoutes from '@/modules/Projects/routes';
import Monitor from '@/modules/Monitor/routes';
// import PageIndexer from '@/modules/PageIndexer/routes';

const routes = [
    ...defaultRoutes,
    ...Monitor,
    // ...PageIndexer,
    // ...projectsRoutes,
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else {
            return { left: 0, top: 0 };
        }
    }
});

export default router;
