<template>
    <ul v-if="items">
        <template v-for="(item, i) of items">
            <li
                v-if="visible(item) && !item.separator"
                :key="item.label || i"
                :class="[
                    {
                        'layout-menuitem-category': root,
                        'active-menuitem':
                            activeIndex === i && !item.to && !item.disabled,
                    },
                ]"
                role="none"
            >
                <template v-if="root">
                    <div class="layout-menuitem-root-text">
                        {{ item.label }}
                    </div>
                    <appsubmenu
                        :items="visible(item) && item.items"
                        @menuitem-click="$emit('menuitem-click', $event)"
                    >
                    </appsubmenu>
                </template>
                <template v-else>
                    <router-link
                        v-if="item.to"
                        :to="item.to"
                        :class="[
                            item.class,
                            'p-ripple',
                            { 'p-disabled': item.disabled },
                        ]"
                        :style="item.style"
                        @click="onMenuItemClick($event, item, i)"
                        :target="item.target"
                        exact
                        role="menuitem"
                        v-ripple
                    >
                        <i :class="item.icon"></i>
                        <span>{{ item.label }}</span>
                        <i
                            v-if="item.items && !isShowAlways"
                            class="pi pi-fw pi-angle-down menuitem-toggle-icon"
                        ></i>
                        <Badge v-if="item.badge" :value="item.badge"></Badge>
                    </router-link>
                    <a
                        v-if="!item.to"
                        :href="item.url || '#'"
                        :style="item.style"
                        :class="[
                            item.class,
                            'p-ripple',
                            { 'p-disabled': item.disabled },
                        ]"
                        @click="onMenuItemClick($event, item, i)"
                        :target="item.target"
                        role="menuitem"
                        v-ripple
                    >
                        <i :class="item.icon"></i>
                        <span>{{ $t(item.label) }}</span>
                        <i
                            v-if="item.items && !isShowAlways"
                            class="pi pi-fw pi-angle-down menuitem-toggle-icon"
                        ></i>
                        <Badge v-if="item.badge" :value="item.badge"></Badge>
                    </a>
                    <transition name="layout-submenu-wrapper">
                        <appsubmenu
                            v-show="isShow(i, item.items)"
                            :items="visible(item) && item.items"
                            @menuitem-click="$emit('menuitem-click', $event)"
                        ></appsubmenu>
                    </transition>
                </template>
            </li>
            <li
                class="p-menu-separator"
                :style="item.style"
                v-if="visible(item) && item.separator"
                :key="'separator' + i"
                role="separator"
            ></li>
        </template>
    </ul>
</template>
<script>
export default {
    name: "appsubmenu",
    props: {
        items: Array,
        root: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeIndex: null,
        };
    },
    methods: {
        checkItems(items) {
            if (!items) {
                return false;
            }

            let haveChild = items.some(
                (child) =>
                    child.to !== undefined &&
                    child.to.name !== undefined &&
                    child.to.name == this.$router.currentRoute.value.name
            );

            return (
                haveChild ||
                items.some((element) => this.checkItems(element.items))
            );
        },

        isShow(i, items) {
            return (
                this.isShowAlways ||
                this.activeIndex === i ||
                this.checkItems(items)
            );
        },

        isShowAlways() {
            return true;
        },

        onMenuItemClick(event, item, index) {
            if (item.disabled) {
                event.preventDefault();
                return;
            }

            if (!item.to && !item.url) {
                event.preventDefault();
            }

            //execute command
            if (item.command) {
                item.command({ originalEvent: event, item: item });
            }

            this.activeIndex = index === this.activeIndex ? null : index;

            this.$emit("menuitem-click", {
                originalEvent: event,
                item: item,
            });
        },
        visible(item) {
            return typeof item.visible === "function"
                ? item.visible()
                : item.visible !== false;
        },
    },
};
</script>

<style>
.selected-menu-item {
    font-weight: 700;
}
</style>
