const routes = [
    {
        path: '/monitor',
        name: 'Monitor',
        component: () => import('@/modules/Monitor/views/Monitor.vue')

    },
    {
        path: '/admin',
        name: 'MonitorAdmin',
        component: () => import('@/modules/Monitor/views/Admin/Monitor.vue')

    },
];

export default routes;
