export const ROLE_USER = 1;
export const ROLE_ADMIN = 9;
export const ROLE_TL = 7;

export const ROLES = [
    { value: ROLE_USER, name: 'Пользователь' },
    { value: ROLE_ADMIN, name: 'Администратор' },
    { value: ROLE_TL, name: 'TL' },
];

export default {
    valueToName(value) {
        let role = ROLES.find((el) => el.value == value);

        return role ? role.name : 'Неизвестная роль';
    }
}
