import apiService from "@/common/api/apiService";

const MY_INFO = '/api/me';
const EDIT_USER = '/api/user/{userId}';
const ALL_USERS = '/api/users';

export default {
    getMyUserInfo() {
        return apiService.api.get(MY_INFO);
    },

    editUser(user) {
        return apiService.api.patch(EDIT_USER.replace('{userId}', user.id), user);
    },

    getAllUsers() {
        return apiService.api.get(ALL_USERS);
    },
}
